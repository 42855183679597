<template>
  <div class="wrapper">
    <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
    >
      <div class="tit">基本信息</div>
      <el-form-item label="姓名" prop="name">
        <el-input v-model="ruleForm.name" placeholder="请输入姓名" ></el-input>
      </el-form-item>
      <el-form-item label="身份证" prop="idNo">
        <el-input v-model="ruleForm.idNo" @blur="certificate_number_yanzheng"  placeholder="请输入身份证号"></el-input>
      </el-form-item>
      <el-form-item label="籍贯" prop="nativeName">
        <el-input v-model="ruleForm.nativeName" placeholder="请输入籍贯"></el-input>
      </el-form-item>
      <el-form-item label="民族" prop="nationality">
        <el-input v-model="ruleForm.nationality" placeholder="请输入民族"></el-input>
      </el-form-item>
      <el-form-item label="工作单位" prop="company">
        <el-input v-model="ruleForm.company" placeholder="请输入工作单位"></el-input>
      </el-form-item>
      <el-form-item label="文化程度" prop="education">
        <el-select v-model="ruleForm.education" placeholder="请选择文化程度">
          <el-option
              v-for="item in edu_list"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="政治面貌" prop="politicalStatus">
        <el-select v-model="ruleForm.politicalStatus" placeholder="请选择政治面貌" @change="politicalStatusChange">
          <el-option
              v-for="item in politic_List"
              :key="item.label"
              :label="item.label"
              :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="党委/党总支" prop="cpcOrgName" v-if="ruleForm.politicalStatus == '中共党员'">
        <el-select ref="treeSelect" filterable allow-create default-first-option
          @blur="selectOrgNameBlur" v-model="ruleForm.cpcOrgName" placeholder="请选择党总支" @change="cpcOrgNameChange">
          <el-option
              v-for="item in organizationList"
              :key="item.label"
              :label="item.label"
              :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="党支部" prop="cpcBranchName" v-if="ruleForm.politicalStatus == '中共党员'">
        <el-select filterable allow-create default-first-option
          @blur="selectBranchNameBlur" v-model="ruleForm.cpcBranchName" placeholder="请选择党支部">
          <el-option
              v-for="item in branchList"
              :key="item.label"
              :label="item.label"
              :value="item.label">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="健康状况" prop="health">
        <el-select v-model="ruleForm.health" placeholder="请选择健康状况" :disabled="flag">
          <el-option label="健康" value="健康"></el-option>
          <el-option label="残疾" value="残疾"></el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="是否为特殊困难群体" prop="health">
        <el-select v-model="ruleForm.health" placeholder="是否为特殊困难群体">
          <el-option label="否" value="否"></el-option>
          <el-option label="低保边缘户" value="低保边缘户"></el-option>
          <el-option label="低保户" value="低保户"></el-option>
          <el-option label="残障" value="残障"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属社区" prop="communityCode">
        <el-select
            v-model="ruleForm.communityCode"
            placeholder="请选择社区"
            @change="communityChange">
          <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="所属小区" prop="regionCode">
        <el-select
            v-model="ruleForm.regionCode"
            placeholder="请选择小区">
          <el-option
              v-for="item in regionList"
              :key="item.regioncode"
              :label="item.regionname"
              :value="item.regioncode">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactMobile">
        <el-input v-model="ruleForm.contactMobile" placeholder="请输入联系方式"
                  oninput="value=value.replace(/[^\d]/g,'')"></el-input>
      </el-form-item>
      <el-form-item label="联系地址" prop="address">
        <el-input v-model="ruleForm.address" placeholder="请输入联系地址"></el-input>
      </el-form-item>
      <div class="tit">申请信息</div>
      <el-form-item label="申请项目" prop="serviceTypes">
        <el-select v-model="ruleForm.serviceTypes" class="program-select" multiple placeholder="请选择申请项目"
                  @change="programChange">
          <el-option
              v-for="item in serviceTypesList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务时间" prop="timeType">
        <el-select v-model="ruleForm.timeType" placeholder="请选择服务时间">
          <el-option
              v-for="item in time_list"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专业特长" prop="skills">
        <el-input :rows="3" type="textarea" v-model="ruleForm.skills" placeholder="请输入专业特长"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :disabled="tijiao" @click="submitForm('ruleForm')"
        >{{flag ? '修改' :'新增'}}
        </el-button
        >
        <el-button @click="cancelFn">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {addMember, updateMember, getDictItem, getVolServiceType, queryOrganization, memberDetail} from "@/api/showLove";
import {xiaoquList} from '@/api/shequ/xiaoqu.js'
import {check_id_no} from '@/utils/checkIdNo'
import {stream} from "xlsx";
import {communityOfUser} from "@/api/system";

export default {
  name: "addpoint",
  components: {},
  props: {
    flag: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      id_no_check: '',
      edu_list: [{label: '小学', value: '0'}, {label: '初中', value: '1'}, {label: '高中', value: '2'}, {
        label: '大专',
        value: '3'
      }, {label: '本科', value: '4'}, {label: '研究生', value: '5'}],
      politic_List: [
        {label: '群众', value: '4'},
        {label: '中共党员', value: '1'},
        {label: '共青团员', value: '3'},
        {label: '民革党员', value: '5'},
        {label: '民盟盟员', value: '6'},
        {label: '民建会员', value: '7'},
        {label: '民进会员', value: '8'},
        {label: '农工党党员', value: '9'},
        {label: '致公党党员', value: '10'},
        {label: '九三学社社员', value: '11'},
        {label: '台盟盟员', value: '12'},
        {label: '无党派人士', value: '13'},
      ],
      organizationList: [],
      branchList: [],
      defaultProps:{
        children: 'children',
        label: 'label'
      },
      serviceTypesList: [],
      time_list: [{label: '不限', value: '0'}, {label: '工作日', value: '1'}, {label: '节假日', value: '2'}],
      communitys:[],
      regionList: [],
      ruleForm: {
        uuid: '',
        name: '',
        idNo: '',
        nativeName: '',
        nationality: '',
        company: '',
        education: '',
        politicalStatus: '群众',
        cpcOrgName: '',
        cpcBranchName: '',
        health: '',
        contactMobile: '',
        communityCode: '',
        communityName: '',
        regionCode: '',
        regionName: '',
        address: '',
        serviceTypes: [],
        timeType: '',
        skills: '',
      },
      rules: {
        name: [
          {required: true, message: "请输入姓名", trigger: "blur"},
        ],
        idNo: [
          {required: true, message: "请输入身份证号", trigger: "blur"},
        ],
        politicalStatus: [
          {required: true, message: "请选择政治面貌", trigger: "change"},
        ],
        cpcOrgName: [
          {required: true, message: "请选择党总支", trigger: "change"},
        ],
        cpcBranchName: [
          {required: true, message: "请选择党分支", trigger: "change"},
        ],
        health: [
          {required: true, message: "请选择健康状况", trigger: "change"},
        ],
        contactMobile: [
          {required: true, message: "请输入联系方式", trigger: "blur"},
        ],
        communityCode: [
          {required: true, message: "请选择社区", trigger: "change"},
        ],
        regionCode: [
          {required: true, message: "请选择小区", trigger: "change"},
        ],
        address: [
          {required: true, message: "请输入联系地址", trigger: "blur"},
        ],
        serviceTypes: [
          {required: true, message: "请选择申请项目", trigger: "change"},
        ],
        timeType: [
          {required: true, message: "请选择服务时间", trigger: "change"},
        ],
        skills: [
          {required: true, message: "请输入专业特长", trigger: "blur"},
        ],
      },
      tijiao: false,
    };
  },
  watch: {
    formId: {
      handler(newVal) {
        if(newVal) {
          this.getDetail();
        }
      },
      deep: true
    },
  },
  created() {
  },
  mounted() {
    this.getProType();
    this.getCommunityOfUser();
    this.getOrganizationList();
    if(this.flag) {
      this.getDetail();
    }
  },
  computed: {},
  methods: {
    getDetail() {
      let that = this;
      memberDetail({
        uuid: this.formId
      }).then(res=>{
        if(res.result == 200){
          let data = res.detail;
          let service_types = [];
          var communityCode = '';
          var regionCode = '';
          if(data.service_types) {
            let arr = data.service_types.split(',');
            for(let i = 0; i < arr.length; i++) {
              service_types.push(parseInt(arr[i]));
            }
          }
          if(data.communityname) {
            this.communitys.map(item => {
              if(item.name == data.communityname) {
                communityCode = item.levelcode;
                xiaoquList({
                  userid: that.GLOBAL.adminId(),
                  levelcode:item.levelcode,
                  levelname:item.name,
                  page: 1,
                  pagesize: 9999
                }).then(res=>{
                  if(res.result==200){
                    this.regionList = res.detail.list;
                    this.regionList.map(item1 => {
                      if(item1.regionname == data.regionname) {
                        regionCode = item1.regioncode;
                      }
                    });
                  }else{
                    this.$message.error(res.description);
                  }
                })
              }
            });
          }
          setTimeout(() => {
            this.ruleForm = {
              uuid: data.uuid,
              name: data.name,
              idNo: data.id_no,
              nativeName: data.native,
              nationality: data.nationality,
              company: data.company,
              education: data.education,
              politicalStatus: data.political_status,
              cpcOrgName: data.cpc_org_name,
              cpcBranchName: data.cpc_branch_name,
              health: data.health,
              contactMobile: data.contact_mobile,
              communityCode: communityCode,
              communityName: data.communityname,
              regionCode: regionCode,
              regionName: data.regionname,
              address: data.address,
              serviceTypes: service_types,
              timeType: data.time_type + '',
              skills: data.skills,
            };
          }, 500);
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    // 获取党支部信息列表
    getOrganizationList() {
      queryOrganization().then( res => {
        if(res.result == 200) {
          let list = res.detail;
          this.organizationList = list;
        }
      })
    },
    cpcOrgNameChange(data) {
      this.ruleForm.cpcBranchName = '';
      if(data) {
        this.organizationList.map(item => {
          if(item.label == data) {
            this.branchList = item.children;
          }
        })
      }
    },
    politicalStatusChange() {
      this.ruleForm.cpcOrgName = '';
      this.ruleForm.cpcBranchName = '';
    },
    communityChange(data) {
      this.ruleForm.regionCode = '';
      if(data) {
        this.communitys.map(item => {
          if(item.levelcode == data) {
            this.ruleForm.communityName = item.name;
          }
        });
        this.getRegionList();
      }
    },
    getRegionList() {
      let that = this;
      let data = {
        userid: that.GLOBAL.adminId(),
        levelcode:that.ruleForm.communityCode,
        levelname:that.ruleForm.communityName,
        page: 1,
        pagesize: 9999
      }
      xiaoquList(data).then(res=>{
        if(res.result==200){
          this.regionList = res.detail.list;
        }else{
          this.$message.error(res.description);
        }
      })
    },
    selectOrgNameBlur(e) {
      let value = e.target.value;
      if (value) {
        this.ruleForm.cpcOrgName = value;
      }
    },
    selectBranchNameBlur(e) {
      let value = e.target.value;
      if (value) {
        this.ruleForm.cpcBranchName = value;
      }
    },
    //获取志愿项目类型
    getProType() {
      getVolServiceType().then(res => {
        if (res.result == 200) {
          this.serviceTypesList = res.detail.list;
        } else {
          this.$message.error(res.description)
        }
      })
    },
    getCommunityOfUser() {
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res => {
        if (res.result == 200) {
          this.communitys = res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //申请项目选择
    programChange(value) {
      console.log("value:", value);
      // this.ruleForm.serviceTypes = this.serviceTypesList[value].label;
    },
    // 正则判断身份证号
    certificate_number_yanzheng() {
      if (this.ruleForm.idNo.replace(/\s*/g, "") != "") {
        this.id_no_check = check_id_no(this.ruleForm.idNo.replace(/\s*/g, ""))
        if (this.id_no_check != 'ok') {
          this.$message.error(this.id_no_check);
        }
      } else {
        this.id_no_check = '';
      }
    },
    //取消添加
    cancelFn(type) {
      // console.log(type)
      this.$refs.ruleForm.resetFields();
      if (type) {
        this.$emit("closeAddMemberDialog", type);
      } else {
        this.$emit("closeAddMemberDialog");
      }
    },
    //重置表单
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    //确认添加
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.id_no_check != '' && this.id_no_check != 'ok') {
            this.$message.error(this.id_no_check);
            return;
          }
          if (this.ruleForm.contactMobile.trim().length !== 11) {
            this.$message.error('请输入正确的手机号');
            return;
          }
          if(this.flag) {
            // 修改 updateMember
            this.$confirm("是否确认修改社员信息?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).then( () => {
              this.tijiao = true;
              let serviceTypes = this.ruleForm.serviceTypes.join(',');
              updateMember({
                uuid: this.ruleForm.uuid,
                name: this.ruleForm.name,
                idNo: this.ruleForm.idNo,
                contactMobile: this.ruleForm.contactMobile,
                nativeName: this.ruleForm.nativeName,
                nationality: this.ruleForm.nationality,
                company: this.ruleForm.company,
                education: this.ruleForm.education,
                politicalStatus: this.ruleForm.politicalStatus,
                cpcOrgName: this.ruleForm.cpcOrgName,
                cpcBranchName: this.ruleForm.cpcBranchName,
                communityCode: this.ruleForm.communityCode,
                health: this.ruleForm.health,
                regionCode: this.ruleForm.regionCode,
                address: this.ruleForm.address,
                serviceTypes: serviceTypes,
                timeType: this.ruleForm.timeType,
                skills: this.ruleForm.skills
              }).then( res => {
                this.tijiao = false;
                if (res.result == 200) {
                  this.$message.success("修改成功");
                  this.cancelFn("add");
                } else {
                  this.$message.error(res.description);
                }
              })
            })
            .catch( () => {})
          } else {
            // 新增
            this.$confirm("是否确认添加社员?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
            .then(() => {
              this.tijiao = true;
              let serviceTypes = this.ruleForm.serviceTypes.join(',');
              addMember({
                name: this.ruleForm.name,
                idNo: this.ruleForm.idNo,
                nativeName: this.ruleForm.nativeName,
                nationality: this.ruleForm.nationality,
                company: this.ruleForm.company,
                education: this.ruleForm.education,
                politicalStatus: this.ruleForm.politicalStatus,
                cpcOrgName: this.ruleForm.cpcOrgName,
                cpcBranchName: this.ruleForm.cpcBranchName,
                health: this.ruleForm.health,
                contactMobile: this.ruleForm.contactMobile,
                communityCode: this.ruleForm.communityCode,
                regionCode: this.ruleForm.regionCode,
                address: this.ruleForm.address,
                serviceTypes: serviceTypes,
                timeType: this.ruleForm.timeType,
                skills: this.ruleForm.skills
              }).then(res => {
                this.tijiao = false;
                if (res.result == 200) {
                  this.$message.success("添加成功");
                  this.cancelFn("add");
                } else {
                  this.$message.error(res.description);
                }
              })
            })
            .catch(() => {
            });
          }
          
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.tit {
  margin-bottom: 20px;
  margin-left: 20px;
  font-size: 16px;
  color: #666;
}

.tit::before {
  content: '';
  width: 3px;
  height: 16px;
  background: #409EFF;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.program-select {
  width: 100%;
}
.setstyle {
	min-height: 200px;
	padding: 0 !important;
	margin: 0;
	overflow: auto;
	cursor: default !important;
}
</style>
