<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="姓名">
          <el-input v-model="formInline.name" clearable placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="formInline.contactMobile" clearable placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="所属社区" prop="communityCode">
          <el-select clearable
              v-model="formInline.communityCode" @change="getRegionList"
              placeholder="请选择社区">
            <el-option
                v-for="item in communitys"
                :key="item.levelcode"
                :label="item.name"
                :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属小区" prop="regionCode">
          <el-select clearable
            v-model="formInline.regionCode"
            placeholder="请选择小区">
            <el-option
                v-for="item in regionList"
                :key="item.regioncode"
                :label="item.regionname"
                :value="item.regioncode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="服务类型">
          <el-select v-model="formInline.serviceTypes" clearable placeholder="请选择服务类型">
            <el-option v-for="item in serviceTypeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否为特殊困难群体">
          <el-select style="width: 220px;" clearable v-model="formInline.health" placeholder="请选择是否为特殊困难群体">
            <el-option label="否" value="否"></el-option>
            <el-option label="低保边缘户" value="低保边缘户"></el-option>
            <el-option label="低保户" value="低保户"></el-option>
            <el-option label="残障" value="残障"></el-option>
            <el-option label="其他" value="其他"></el-option>
          </el-select>
        </el-form-item>
		<el-form-item label="政治面貌" prop="politicalStatus">
		  <el-select style="width: 220px;" clearable v-model="formInline.political" placeholder="请选择政治面貌" >
		    <el-option
		        v-for="item in politic_List"
		        :key="item.label"
		        :label="item.label"
		        :value="item.label">
		    </el-option>
		  </el-select>
		</el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch" icon="el-icon-search">查询</el-button>
          <el-button type="primary" @click="addVolunteer" icon="el-icon-plus">新增</el-button>
          <!-- <el-button type="primary" @click="addMoreBlackFn('more')">导入</el-button>
          <a href="/volunteers.xlsx" target="_blank" style="margin-left: 10px">
          <el-button type="primary" icon="el-icon-download">导入模板下载</el-button>
          </a> -->
          <el-button type="primary"  v-if="tableData.length>0" @click="exportXcle" icon="el-icon-download" style="margin-left: 10px">导出</el-button>
          <el-button v-if="multipleSelection.length > 0" type="default" @click="auditMore" icon="el-icon-tickets">批量审核</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column
        :selectable="selectable"
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column
        label="序号"
        type="index"
        :index="indexMethod">
      </el-table-column>
      <el-table-column
        label="姓名"
        prop="name">
      </el-table-column>
      <el-table-column
        label="性别">
        <template slot-scope="scope">
          <span>{{ getUserSex(scope.row.id_no)?getUserSex(scope.row.id_no):'-' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="身份证"
        prop="id_no">
      </el-table-column>
      <el-table-column
        label="联系电话"
        prop="contact_mobile">
      </el-table-column>
      <el-table-column label="是否为特殊困难群体" prop="health"></el-table-column>
	  <el-table-column label="政治面貌" prop="political_status"></el-table-column>
      <el-table-column
        label="服务类型">
        <template slot-scope="scope">
          <span>{{ backServiceTypeLabel(scope.row.service_types) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="申请时间"
        prop="create_time">
      </el-table-column>
      <el-table-column
        label="状态">
        <template slot-scope="scope">
          <span style="color: #d00" v-if="scope.row.status === 2 || scope.row.status === 3">{{ backCheckStatusLabel(scope.row.status) }}</span>
          <span v-else>{{ backCheckStatusLabel(scope.row.status) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="审核人">
        <template slot-scope="scope">
          <span>{{ scope.row.auditor?scope.row.auditor:'/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="审核时间">
        <template slot-scope="scope">
          <span>{{ scope.row.autdit_time?scope.row.autdit_time:'/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="320">
          <template slot-scope="scope">
            <el-button
              @click="viewRow(scope.row)"
              type="text"
              size="small">
              <i class="el-icon-view"></i> 查看
            </el-button>
            <el-button
              v-if="scope.row.status == 1"
              @click="viewCode(scope.row)"
              type="text"
              size="small">
              <i class="el-icon-menu"></i> 社员二维码
            </el-button>
            <el-button v-if="scope.row.status === 0"
              @click="auditRow(scope.row)"
              type="text"
              size="small">
              <i class="el-icon-tickets"></i> 审核
            </el-button>
            <el-button @click="editRow(scope.row)" type="text" size="small">
              <i class="el-icon-edit"></i> 修改
            </el-button>
            <el-button @click="recoverRow(scope.row)" v-if="scope.row.status === 3"
              type="text"
              size="small">
              <i class="el-icon-circle-check"></i> 恢复
            </el-button>
            <el-button @click="pauseRow(scope.row)" v-if="scope.row.status === 1"
              type="text"
              size="small">
              <i class="el-icon-video-pause"></i> 暂停
            </el-button>
            <el-button @click="deleteRow(scope.row)"
              type="text"
              size="small">
              <i class="el-icon-delete"></i> 删除
            </el-button>
          </template>
        </el-table-column>
    </el-table>

    <div class="pagebox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalRow">
      </el-pagination>
    </div>

 <el-dialog title="导入" :visible.sync="dialogFormVisible1" width="600px" :before-close="onBeforeClose" :close-on-click-modal="false">
      <el-form :model="form"  ref="form" class="formbox" label-width="130px">
        <el-form-item label="导入文件" prop="file">
          <el-upload ref="upload" class="upload-demo" action="#" :limit="1" accept=".xlsx"
            :on-change="handleChange" :on-remove="handleRemove" :auto-upload="false">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
		<el-button @click="cancleFn1">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 新增社员 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="addVolunteerVisible"
      :title="currentVolunteerId?'修改社员':'新增社员'"
			:before-close="addVolunteerhandleClose"
    >
      <addVolunteer @closeAddMemberDialog="closeAddMemberDialog" :flag="editFlag" :formId="currentVolunteerId" ref="addmemberRef"></addVolunteer>
    </el-dialog>
    

    <el-dialog title="社员详情" :visible.sync="memberdialogVisible" width="800px" v-if="memberInfo.name">
      <div class="memberinfobox">
        <el-row :gutter="20">
          <el-col :span="4">姓名</el-col>
          <el-col :span="8">{{ memberInfo.name?memberInfo.name:'-' }}</el-col>
          <el-col :span="4">身份证号</el-col>
          <el-col :span="8">{{ memberInfo.id_no?memberInfo.id_no:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">籍贯</el-col>
          <el-col :span="8">{{ memberInfo.native?memberInfo.native:'-' }}</el-col>
          <el-col :span="4">民族</el-col>
          <el-col :span="8">{{ memberInfo.nationality?memberInfo.nationality:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">政治面貌</el-col>
          <el-col :span="8">{{ memberInfo.political_status?memberInfo.political_status:'-' }}</el-col>
          <el-col :span="4">党委/党总支</el-col>
          <el-col :span="8">{{ memberInfo.cpc_org_name?memberInfo.cpc_org_name:'-' }}</el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="4">党支部</el-col>
          <el-col :span="8">{{ memberInfo.cpc_branch_name?memberInfo.cpc_branch_name:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">工作单位</el-col>
          <el-col :span="8">{{ memberInfo.company?memberInfo.company:'-' }}</el-col>
          <el-col :span="4">文化程度</el-col>
          <el-col :span="8">{{ getEducationName(memberInfo.education) }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <!-- <el-col :span="4">健康状况</el-col> -->
          <el-col :span="4">是否为特殊困难群体</el-col>
          <el-col :span="8">{{ memberInfo.health?memberInfo.health:'-' }}</el-col>
          <el-col :span="4">联系电话</el-col>
          <el-col :span="8">{{ memberInfo.contact_mobile?memberInfo.contact_mobile:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">所属社区</el-col>
          <el-col :span="8">{{ memberInfo.communityname?memberInfo.communityname:'-' }}</el-col>
          <el-col :span="4">所属小区</el-col>
          <el-col :span="8">{{ memberInfo.regionname?memberInfo.regionname:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">联系地址</el-col>
          <el-col :span="20">{{ memberInfo.address?memberInfo.address:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">申请服务类型</el-col>
          <el-col :span="20">{{ backServiceTypeLabel(memberInfo.service_types) }}</el-col>
        </el-row>


        <el-row :gutter="20">
          <el-col :span="4">专业特长</el-col>
          <el-col :span="8">{{ memberInfo.skills?memberInfo.skills:'-' }}</el-col>
          <el-col :span="4">申请时间</el-col>
          <el-col :span="8">{{ memberInfo.apply_time?memberInfo.apply_time:'-' }}</el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="4">状态</el-col>
          <el-col :span="8">{{ backCheckStatusLabel(memberInfo.status) }}</el-col>
        </el-row>

        <el-row class="checkinfobox">
          <el-row class="tit">审核信息</el-row>
          <el-row :gutter="20">
            <el-col :span="4">审核人</el-col>
            <el-col :span="8">{{ memberInfo.auditor?memberInfo.auditor:'-' }}</el-col>
            <el-col :span="4">审核时间</el-col>
            <el-col :span="8">{{ memberInfo.autdit_time?memberInfo.autdit_time:'-' }}</el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="4">审核结果</el-col>
            <el-col :span="8">{{ backCheckResult(memberInfo.audit_result) }}</el-col>
            <el-col :span="4">审核意见</el-col>
            <el-col :span="8">{{ memberInfo.audit_opinion?memberInfo.audit_opinion:'-' }}</el-col>
          </el-row>
        </el-row>
      </div>
      

    </el-dialog>

    

    <!-- 审核表单 -->
		<el-dialog title="社员审核" width="700px" :visible.sync="dialogFormVisible" :before-close="beforeClose" append-to-body>
			<el-form :model="refundform"  label-width="120px" style="width: 90%;">
				<el-form-item label="审核结果">
					<el-select v-model="refundform.isPass" clearable placeholder="请选择审核结果">
						<el-option
              v-for="item in refundResultFlags"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核意见">
					<el-input type="textarea" clearable v-model="refundform.opinion" placeholder="请输入审核意见"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">{{ auditMoreFlag ? '提交批量审核' : '提交审核'}}</el-button>
    			<el-button @click="cancleFn">取消</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>


    <el-dialog
      :title= 'codeName + " 二维码"'
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="360px">
      <div>
        <img style="display: block; width: 300px; height: auto; margin: auto;" :src="codePicUrl" alt="">
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { getDictItem, getVolServiceType, memberList, deleteMember, auditMember, auditMembers, reEnableMember, pauseMember, addMember, memberDetail, exportMemberList, codePic,importData } from '@/api/showLove'
import { sureMessageBox }　from '@/utils/common'
import addVolunteer from './addVolunteer'
import {communityOfUser} from "@/api/system";
import {xiaoquList} from '@/api/shiminglist/shiminglist.js'
import FileSaver from 'file-saver'
import XLSX from 'xlsx'
export default {
  name:"",
  components:{
    addVolunteer
  },
  props:{},
  data(){
    return {
      serviceTypeOptions: [],
      formInline: {
        name: '',
        contactMobile: '',
        serviceTypes: '',
        health: '',
		political:'',
      },
      refundform:{
        isPass: '',
        opinion: ''
      },
      edu_list: [{label: '-',value: ''}, {label: '小学',value: '0'}, {label: '初中',value: '1'}, {label: '高中',value: '2'},{label: '大专',value: '3'}, {label: '本科',value: '4'}, {label: '研究生',value: '5'}],
      tableData: [],
      communitys:[],
      regionList: [], //小区列表
      currentPage: 1,
      totalRow: 0,
      pageSize: 10,
      checkStatus:[{
        value: 0,
        label: '待审核' //已申请
        },{
          value: 1,
          label: '审核通过'
        },{
          value: 2,
          label: '审核不通过'
        },{
          value: 3,
          label: '暂停'
        },{
          value: 9,
          label: '已退出'
      }],
      refundResultFlags:[
        {
          label: '审核通过',
          value: '1'
        },
        {
          label: '审核不通过',
          value: '2'
        }
      ],
      dialogFormVisible: false,
      curMemberUuid: '',
      memberInfo: {},
      memberdialogVisible: false,
      addVolunteerVisible: false,
      editFlag: false,
      currentVolunteerId: '',
      multipleSelection: [],
      auditMoreFlag: false,
      dialogVisible: false,
      codePicUrl: '',
      codeName: '' ,
	  form: {
	    file: ''
	  },
	  dialogFormVisible1: false,
	  politic_List: [
	    {label: '群众', value: '4'},
	    {label: '中共党员', value: '1'},
	    {label: '共青团员', value: '3'},
	    {label: '民革党员', value: '5'},
	    {label: '民盟盟员', value: '6'},
	    {label: '民建会员', value: '7'},
	    {label: '民进会员', value: '8'},
	    {label: '农工党党员', value: '9'},
	    {label: '致公党党员', value: '10'},
	    {label: '九三学社社员', value: '11'},
	    {label: '台盟盟员', value: '12'},
	    {label: '无党派人士', value: '13'},
	  ],
    }
  },
  activated() {
    this.getList()
  },
  watch:{},
  created(){},
  mounted(){
    // this.getList();
    this.getServiceTypes();
    this.getCommunityOfUser();
  },
  computed:{},
  methods:{
    //过滤已经审核过的数据，不给用户选择
    selectable: function(row, index){
      if(row.status === 0){
        return true;
      }else{
        return false;
      }
    },
    //批量选择
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection)
    },
    //返回状态文本
    backCheckStatusLabel(val){
      if(!val){
        return "-"
      }
      let item = this.checkStatus.filter(item => item.value === val)
      return item[0].label;
    },
    //返回服务类型文本
    backServiceTypeLabel(vals){
      if(!vals){
        return "-"
      }
      if(vals){
        let valsArr = vals.split(',');
        let labelsArr = [];
        valsArr.forEach(val=>{
          this.serviceTypeOptions.forEach(service=>{
            if(val == service.id){
              labelsArr.push(service.name)
            }
          })
        })
        return labelsArr.join(',')
      }
    },
    getEducationName(value) {
      if(!value){
        return "-"
      }
      return this.edu_list.filter(education => education.value == value)[0].label

    },
    //返回审核结果文本
    backCheckResult(val){
      if(val){
        let item = this.refundResultFlags.filter(item => item.value == val)
        return item[0].label;
      }else{
        return '-'
      }
    },
    //获取性别
    getUserSex(id){
      if(!id){
        return false;
      }
      let sex;
      if(id.length == 15 ){
          if(parseInt(id.charAt(14)/2)*2!=id.charAt(14)){
              sex="男";
          }
          else{
              sex="女";
          }
      }else if(id.length ==18 ){
          if(parseInt(id.charAt(16)/2)*2!=id.charAt(16)){
              sex="男";
          }
          else{
              sex="女";
          }
      }return sex;
    },
    //获取服务类型
    getServiceTypes(){
      // getDictItem({
      //   type: 'vol_serveice_type'
      // }).then(res=>{
      //   this.serviceTypeOptions = res.detail;
      // })
      getVolServiceType().then(res => {
        this.serviceTypeOptions = res.detail.list;
      })
    },
    getCommunityOfUser() {
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res => {
        if (res.result == 200) {
          this.communitys = res.detail;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    getRegionList(e) {
      this.$set(this.formInline, 'regionCode','');

      let that = this;
      let data = {
        userid: that.GLOBAL.adminId(),
        levelcode:that.formInline.communityCode
      }
      xiaoquList(data).then(res=>{
        if(res.result==200){
          this.regionList = res.detail;
        }
      })
    },
    //获取订单列表
    getList(){
      memberList({
        name: this.formInline.name,
        contactMobile: this.formInline.contactMobile,
        communityCode: this.formInline.communityCode,
        regionCode: this.formInline.regionCode,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        serviceTypes: this.formInline.serviceTypes,
        health: this.formInline.health,
		political: this.formInline.political,
      }).then(res=>{
        // console.log(res)
        if(res.result == 200){
          this.tableData = res.detail.list;
          this.totalRow = res.detail.totalRow;
          //已审核的设置不可选择
          this.multipleSelection = [];
        }else{
          this.$message.warning(res.description)
        }
      })
      
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    //新增社员
    addVolunteer() {
      this.addVolunteerVisible = true;
    },
    //关闭新增社员弹框
		addVolunteerhandleClose(done){
			this.$refs.addmemberRef.resetForm();
      this.currentVolunteerId = '';
      this.editFlag = false;
			done()
		},
    //取消新增社员操作
		closeAddMemberDialog(data){
			// console.log(data)
      this.currentVolunteerId = '';
      this.editFlag = false;
			this.addVolunteerVisible = false;
			if(data == 'add'){
				this.onSearch();
			}
		},
    // 修改社员
    editRow(row) {
      this.editFlag = true;
      this.currentVolunteerId = row.uuid;
      this.addVolunteer();
    },
    //社员删除
    deleteRow(row){
      sureMessageBox('是否确认删除该社员信息？').then(res=>{
        deleteMember({
          uuid : row.uuid 
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //社员审核
    auditRow(row){
      this.dialogFormVisible = true;
      this.auditMoreFlag = false;
      this.curMemberUuid = row.uuid;
      
    },
    //批量审核
    auditMore(){
      this.dialogFormVisible = true;
      this.auditMoreFlag = true;
      let auditUuids = []
      this.multipleSelection.map(item=>{
        auditUuids.push(item.uuid)
      })
      this.curMemberUuid = auditUuids.join(',');
    },
    //取消审核
		cancleFn(){
			this.dialogFormVisible = false;
			this.refundform = {
				isPass: '',
				opinion: ''
			}
		},
    //提交审核
		onSubmit() {
			if(this.refundform.isPass === ''){
				this.$message.warning('请选择审核结果');
				return false;
			}
			if(this.refundform.isPass==='2' && this.refundform.opinion === ''){
				this.$message.warning('请输入审核意见');
				return false;
			}
      
      //批量审核
      if(this.auditMoreFlag){
        sureMessageBox('是否确认提交批量审核？').then(res=>{
          auditMembers({
            uuids: this.curMemberUuid,
            isPass:  this.refundform.isPass,
            opinion: this.refundform.opinion,
            userId: this.GLOBAL.adminId()
          }).then(res=>{
            if(res.result == 200){
              this.$message.success('操作成功')
              this.getList();
              this.cancleFn();
            }else{
              this.$message.warning(res.description)
            }
          })
        }).catch(err=>{
          console.log(err)
        })
      }else{
        sureMessageBox('是否确认提交审核？').then(res=>{
          auditMember({
            uuid: this.curMemberUuid,
            isPass:  this.refundform.isPass,
            opinion: this.refundform.opinion,
            userId: this.GLOBAL.adminId()
          }).then(res=>{
            if(res.result == 200){
              this.$message.success('操作成功')
              this.getList();
              this.cancleFn();
            }else{
              this.$message.warning(res.description)
            }
          })
        }).catch(err=>{
          console.log(err)
        })
      }
      
			
		},
    //点击关闭按钮重置表单内容
		beforeClose(done){
			this.cancleFn();
			done();
		},

    //暂停
    pauseRow(row){
      sureMessageBox('是否确认暂停该社员的服务？').then(res=>{
        pauseMember({
          uuid: row.uuid  
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //恢复
    recoverRow(row){
      sureMessageBox('是否确认恢复该社员的服务？').then(res=>{
        reEnableMember({
          uuid: row.uuid  
        }).then(res=>{
          if(res.result == 200){
            this.$message.success('操作成功')
            this.getList();
          }else{
            this.$message.warning(res.description)
          }
        })
      }).catch(err=>{
        console.log(err)
      })
    },
    //自定义序号
    indexMethod(index) {
      return (this.currentPage - 1)*this.pageSize + (index+1);
    },
    //查询
    onSearch() {
      this.currentPage = 1;
      this.getList();
    },
    //查看社区二维码
    viewCode(row){
      codePic({
        uuid: row.uuid  
      }).then(res=>{
        if(res.result == 200){
          this.dialogVisible = true;
          this.codePicUrl = res.detail;
          this.codeName = row.name;
        }else{
          this.$message.warning(res.description)
        }
      })
      

    },
    handleClose(done) {
      this.codeName = '';
      this.codePicUrl = '';
      done();
    },
    //查看社员详情
    viewRow(row){
      this.memberdialogVisible = true;
      memberDetail({
        uuid: row.uuid  
      }).then(res=>{
        if(res.result == 200){
          this.memberInfo = res.detail;
        }else{
          this.$message.warning(res.description)
        }
      })
    },
    //导出社员列表
    exportXcle() {
      exportMemberList({
        name: this.formInline.name,
        contactMobile: this.formInline.contactMobile,
        communityCode: this.formInline.communityCode,
        regionCode: this.formInline.regionCode,
        // pageSize: this.pageSize,
        // pageNum: this.currentPage,
        serviceTypes: this.formInline.serviceTypes,
        health: this.formInline.health,
		 political: this.formInline.political
      }).then(res=>{
        if(res.result == 201){ //.result == 200
          this.$message.warning(res.description)
        }else{
          const data = res;
          const a = document.createElement('a');
          const blob = new Blob([data],{type:"application/octet-stream"});
          const blobUrl = window.URL.createObjectURL(blob);
          a.style.display = 'none';
          a.download = '社员信息列表.xls'; //文件名
          a.href = blobUrl;
          a.click();
        }
      })
    },
	
	
	cancleFn1(){
		this.dialogFormVisible1 = false;
		this.form.file = "";
		this.$refs.upload.clearFiles();
	},
	onBeforeClose(done){
	  done();
	  this.cancleFn1();
	},
	handleChange(file, fileList) {
	  console.log(fileList);
	  this.form.file = fileList[0].raw;
	},
	handleRemove(file, fileList) {
	  console.log(fileList);
	  this.form.file = "";
	},
	addMoreBlackFn(type) {
	  this.handleRemove();
	  this.dialogFormVisible1 = true;
	},
	submitForm(formName) {
	  this.$refs[formName].validate((valid) => {
	    if (valid) {
	        let formData = new FormData();
	        formData.append('file', this.form.file);
	        importData(formData, {
	        }).then(res=>{
	          if(res.result == 200){
	            let detail = res.detail;
	            this.$confirm(detail, '提示', {
	              confirmButtonText: '确定',
	              cancelButtonText: '取消',
	            }).then(() => {
	              this.onSearch();
				  this.cancleFn1();
	            }).catch(() => {
				  this.onSearch();
				  this.cancleFn1();
	            })
	          } else {
	            this.$message.error(res.description);
	          }
	        })
	    } else {
	      return false;
	    }
	  });
	},
  }
}
</script>

<style lang="scss" scoped>
  .searchbox {
    margin: 20px 10px;
  }

  
  .pagebox{
    margin: 20px 0;
    text-align: right;
  }

  .memberinfobox{
    width: 96%;
    margin: auto;
    ::v-deep .el-row{
      margin-bottom: 15px;
    }
    ::v-deep .el-col{
      padding: 4px 0;
    }
    ::v-deep .el-col-4{
      background-color: #f5f5f5;
    }
    .checkinfobox{
      margin-top: 30px;
      .tit{
        font-size: 16px;
        color: #318fdf;
        line-height: 2em;
        border-bottom: 2px solid #318fdf;
      }
    }
  }
</style>